import React from "react"
import PageLoader from "../components/page-loader"
import LoginLayout from "../components/login-layout"

const Callback = () => {
  // const verifyUser = () => {
  //   if (typeof window !== "undefined") {
  //     auth.parseHash(
  //       { hash: window.location.hash },
  //       function (err, authResult) {
  //         if (err) {
  //           console.error("Unable to parse hash:", err)
  //           return navigate("/login?error=unauthorized")
  //         }

  //         console.log("authResult", authResult)
  //         navigate("/account")
  //       }
  //     )
  //   }
  // }

  // useEffect(() => {
  //   verifyUser()
  // }, [])

  return (
    <LoginLayout>
      <PageLoader />
    </LoginLayout>
  )
}

export default Callback
